<template>
    <div>
        <el-upload
            class="upload-demo"
            ref="upload"
            :action="uploadUrl"
            :on-change="handleChange"
            :file-list="fileList"
            :data="uploadData"
            :on-success="onSuccess"
            multiple
            :before-upload="beforeUpload"
            drag
            :auto-upload="true">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
    </div>
</template>

<script>
    import {mapActions} from "vuex";


    export default {
        name: "uploadFile",
        data(){
            return {
                fileList:[],
                tableData:[],
                uploadUrl:'',
                uploadData:{}
            }
        },
        methods:{
            ...mapActions('common',['getOssInfo']),
            handleChange( file){
                if(file.status != 'ready')
                {
                    this.tableData.push({
                        name: file.name,
                        type: file.name.split('.').splice(-1),
                        size:  file.size,
                        status: file.status
                    })
                    this.$emit('onChange',this.tableData)
                }
            },
            async beforeUpload(file){
                let _this = this
                await this.getOssInfo('images').then(res => {
                    this.uploadUrl = res.data.host
                    _this.path = res.data.dir+'/'+file.name
                    let data = {
                        key:_this.path,
                        policy:res.data.policy,
                        OSSAccessKeyId: res.data.accessid,
                        signature:res.data.signature,
                        callback:res.data.callback,
                    }
                    _this.uploadData = data
                })
            },
            onSuccess(response, file){
                this.$refs.upload.handleRemove(file)
                this.$emit('onSuccess',response.data)
            }
        }
    }
</script>

<style scoped>

</style>
